import makeStyles from "@material-ui/styles/makeStyles";

const styles = (theme) => {
	return makeStyles(theme => ({
		item: {
			position: "relative",
			"&:last-child a:after": {
				display: "none"
			},
			"&:first-child a": {
				textAlign: "right"
			}
		},
		link: {
			color: "#FFFFFF",
			display: "block",
			opacity: 0.7,
			fontSize: 18,
			margin: "0 10px",
			"&:hover": {
				opacity: 0.8
			},
			"&:after": {
				content: "''",
				background: "#FFFFFF",
				height: "100%",
				width: 2,
				position: "absolute",
				top: 0,
				right: 0
			},
			[theme.breakpoints.up("lg")]: {
				fontSize: 24,
				minWidth: 170
			}
		},
		selected: {
			opacity: 1
		}
	}))();
};

export default styles;
