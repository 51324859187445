import clsx from "clsx";
import Metamenu from "./Metamenu";

import styles from "./Footer.styles";

const Footer = () => {
	const css = styles();

	return (
		<footer className={clsx(css.footer, "mco-view-component-footer")}>
			<Metamenu />
		</footer>
	);
};

export default Footer;
