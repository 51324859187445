import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	metamenu: {
		alignItems: "center",
		display: "flex",
		minHeight: "100%",
		width: "100%"
	}
}));

export default styles;
