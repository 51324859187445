import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		background: theme.palette.header?.background,
		borderBottom: "1px solid #6b6b6b",
		borderLeft: "1px solid #6b6b6b",
		position: "fixed",
		left: 0,
		right: 0,
		top: 0,
		zIndex: 100,
		display: "flex",
		alignItems: "center",
		height: 70,
		paddingLeft: 16,
		paddingRight: 16,
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		[theme.breakpoints.up("lg")]: {
			left: "auto",
			height: "100%",
			width: 300,
			paddingLeft: 32,
			paddingRight: 32,
			display: "block",
			overflow: "auto"
		}
	},
	logoWrapper: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		[theme.breakpoints.up("lg")]: {
			display: "block",
			position: "relative",
			marginTop: 19,
			marginBottom: -6
		}
	},
	logo: {
		height: 40,
		[theme.breakpoints.up("sm")]: {
			height: 60
		},
		[theme.breakpoints.up("lg")]: {
			height: "auto",
			width: "100%",
			marginRight: 0
		}
	},
	logoAdditionMobile: {
		fontSize: 11.2,
		color: "#FFFFFF",
		lineHeight: 1.1,
		margin: "0 auto",
		whiteSpace: "nowrap",
		"& span:first-of-type": {
			fontSize: 11.8
		},
		"& span:nth-of-type(2)": {
			fontSize: 19
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: 13.2,
			margin: "0 0 0 16px",
			"& span:first-of-type": {
				fontSize: 13.8
			},
			"& span:nth-of-type(2)": {
				fontSize: 22
			}
		}
	},
	logoAdditionDesktopTop: {
		[theme.breakpoints.up("lg")]: {
			display: "block",
			fontSize: 22,
			color: "#FFFFFF",
			lineHeight: 1.1,
			marginBottom: 14,
			"& span": {
				fontSize: 36.8
			}
		}
	},
	logoAdditionDesktopBottom: {
		[theme.breakpoints.up("lg")]: {
			display: "block",
			fontSize: 23.4,
			color: "#FFFFFF",
			marginRight: "-10px",
			marginTop: 4,
			lineHeight: 1.1
		}
	},
	portraitDesktop: {
		[theme.breakpoints.up("lg")]: {
			display: "block",
			marginLeft: "auto",
			width: "100%"
		}
	},
	portraitMobile: {
		display: "block",
		margin: "0 -20px 0 auto",
		right: 0,
		height: "100%"
	}
}));

export default styles;
