import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import Header from "../../components/views/Header";
import Footer from "../../components/views/Footer";
import Animation from "../../components/views/Animation";
import Elements from "../../components/Elements";

import styles from "./Default.styles";

const Default = () => {
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData || {};
	const css = styles();

	return (
		<div className={clsx(css.root, "mco-view-root", layoutVariant)}>
			<Header />
			<div className={clsx(css.default, "mco-view-default")}>
				{layoutVariant !== "no-animation" && <Animation />}
				<div>
					<Elements />
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default Default;
