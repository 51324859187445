import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactAnimation from "@micado-digital/react-animation/ReactAnimation";
import Container from "../Container";

import styles from "./Animation.styles";

const Animation = () => {
	const { REACT_APP_PATH } = process.env;
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { animationID } = pageData || {};

	return (
		<div className={clsx(css.animation, "mco-view-component-animation")}>
			<Container className={clsx(css.container, "mco-view-component-animation__container")}>
				<ReactAnimation
					api={`${REACT_APP_PATH}/animationV3.json.api`}
					id={animationID}
					mediaFormats={{
						xs: "animation-mobile",
						sm: "animation"
					}}
					webpFallback
				/>
			</Container>
		</div>
	);
};

export default Animation;
