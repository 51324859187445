import styles from "./Location.styles";

const Location = ({...props}) => {
	const css = styles();

	return (
		<div className={css.location}>
			<span>{props.title}</span>
			<a href={"tel:" + props.phoneLink}>{props.phoneLabel}</a>
		</div>
	);
};

export default Location;
