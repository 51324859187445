import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	footer: {
		background: "#3A3A3A",
		display: "flex",
		alignItems: "center",
		minHeight: 50,
		marginTop: "auto",
		padding: 16,
		[theme.breakpoints.up("lg")]: {
			minHeight: 70,
			paddingRight: 24
		}
	}
}));

export default styles;
