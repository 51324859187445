import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	menuHeader: {
		color: "#FFFFFF",
		right: "100% !important",
		transition: "right .3s ease",
		position: "fixed",
		top: 0,
		background: "#3a3a3a",
		width: "100%",
		zIndex: 1
	},
	container: {
		alignItems: "center",
		display: "flex",
		height: 90,
		maxWidth: "none",
		[theme.breakpoints.up("lg")]: {
			height: 120,
			paddingLeft: 60,
			paddingRight: 51
		}
	},
	close: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		height: theme.spacing(6),
		justifyContent: "center",
		marginLeft: "auto",
		width: theme.spacing(6)
	},
	inner: {
		cursor: "pointer",
		height: 19,
		position: "relative",
		width: 32
	},
	bar: {
		background: "#FFFFFF",
		height: 3,
		marginTop: 7,
		position: "absolute",
		width: "100%"
	}
}));

export default styles;
