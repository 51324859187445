import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import ReactLink from "@micado-digital/react-link/ReactLink";
import UiContext from "@UiContext";
import Hamburger from "./Hamburger";
import DesktopMenu from "./Desktopmenu";
import Location from "./Location";
import MobileMenu from "./Mobilemenu";
import PageContext from "@PageContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

import styles from "./Header.styles";

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [pageData] = useContext(PageContext);
	const [ui] = useContext(UiContext);
	const { menuGroups } = pageData || {};
	const { _loading } = ui;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));
	const css = styles();

	const menuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Hauptmenü");

	useEffect(() => {
		if (!_loading) {
			setMenuOpen(false);
		}
	}, [_loading]);

	clearAllBodyScrollLocks();

	setTimeout(function () {
		clearAllBodyScrollLocks();
	}, 100);

	setTimeout(function () {
		clearAllBodyScrollLocks();
	}, 500);

	if (!menuOpen) {
		document.body.classList.add("menu-closed");
		document.body.classList.remove("menu-open");
	} else {
		document.body.classList.remove("menu-closed");
		setTimeout(function () {
			document.body.classList.add("menu-open");
		}, 100);
	}

	const Menu = () => {
		if (!isMobile) {
			return "";
		} else {
			return (
				<MobileMenu menuGroup={menuGroup} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			);
		}
	};

	return (
		<header className={clsx(css.header, "mco-view-component-header")}>
			<ReactLink to="/" className={css.logoWrapper}>
				{!isMobile && (
					<p className={css.logoAdditionDesktopTop}>
						<span>Architektur</span>
						<br />
						Projektmanagement
					</p>
				)}
				<img
					className={clsx(css.logo, "mco-view-component-header__logo")}
					src="/img/architektur-wieser.png"
					alt=""
				/>
				{isMobile ? (
					<p className={css.logoAdditionMobile}>
						<span>Büro Franz Wieser</span>
						<br />
						<span>Architektur</span>
						<br />
						Projektmanagement
					</p>
				) : (
					<p className={css.logoAdditionDesktopBottom}>Büro Franz Wieser</p>
				)}
			</ReactLink>
			{isMobile && (
				<>
					<Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
					<img
						className={css.portraitMobile}
						src="/img/portrait-header-small.png"
						srcSet="/img/portrait-header-hd-small.png 2x"
						alt="Franz Wieser"
					/>
				</>
			)}
			{menuOpen && Menu()}
			{!isMobile && (
				<>
					<DesktopMenu menuGroup={menuGroup} />
					<img
						className={css.portraitDesktop}
						src="/img/portrait-header.png"
						srcSet="/img/portrait-header-hd.png 2x"
						alt="Franz Wieser"
					/>
					<Location
						title="Büro Kitzbühel"
						phoneLabel="+43 5356 66898"
						phoneLink="+43535666898"
					></Location>
					<Location
						title="Büro Hollersbach"
						phoneLabel="+43 6562 8410"
						phoneLink="+4365628410"
					></Location>
				</>
			)}
		</header>
	);
};

export default Header;
