import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	megamenu: {

	},
	container: {

		[theme.breakpoints.up("lg")]: {

		}
	},
	desktopmenu: {

	}
}));

export default styles;
