import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		"& a": {
			color: "#FFFFFF",
			opacity: 1,
			fontSize: 40,
			lineHeight: "40px",
			fontWeight: 400,
			display: "block",
			textAlign: "right",
			"&:after": {
				top: "13px",
				left: 0,
				width: "13px",
				height: "13px",
				content: "''",
				position: "absolute",
				background: "#FFFFFF"
			}
		}
	},
	hideOnDesktop: {
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	}
}));

export default styles;
