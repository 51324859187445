import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme as customTheme } from "./themes/project";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import Ui from "@micado-digital/react-ui/components/Ui";
import Views from "./views";
import "./css/index.css";

const theme = createTheme(customTheme);

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Ui>
				<Suspense fallback={<></>}>
					<CssBaseline />
					<BrowserRouter>
						<WebPageData>
							<Routes>
								<Route path="/*" element={<Views />} />
							</Routes>
						</WebPageData>
					</BrowserRouter>
				</Suspense>
			</Ui>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
