import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import Container from "../../../Container";

import styles from "./MenuHeader.styles";

const MenuHeader = ({ menuOpen, setMenuOpen }) => {
	const css = styles();

	const detectVariant = count => {
		let initial = null;
		let animate = null;

		switch (count) {
			case 2:
				initial = "90deg";
				animate = "-45deg";
				break;
			default:
				initial = "90deg";
				animate = "45deg";
		}

		return {
			initial: {
				rotate: initial
			},
			animate: {
				rotate: animate
			}
		};
	};

	return (
		<div className={clsx(css.menuHeader, "mco-view-component-menu-header")}>
			<Container className={clsx(css.container, "mco-view-component-menu-header__container")}>
				<div
					className={clsx(css.close, "mco-view-component-menu-header-close")}
					onClick={() => setMenuOpen(!menuOpen)}
				>
					<motion.div
						animate="animate"
						className={clsx(css.inner, "mco-view-component-menu-header-close__inner")}
						initial="initial"
					>
						{[...Array(2)].map((x, i) => (
							<motion.div
								className={clsx(css.bar, "mco-view-component-menu-header-close__bar")}
								key={i + 1}
								variants={detectVariant(i + 1)}
							/>
						))}
					</motion.div>
				</div>
			</Container>
		</div>
	);
};

export default MenuHeader;
