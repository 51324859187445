import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	animation: {
		height: "60vh",
		marginTop: 70,
		maxHeight: 325,
		[theme.breakpoints.up("lg")]: {
			marginTop: 0
		}
	},
	container: {
		maxWidth: "none",
		height: "100%",
		padding: 0,
		position: "relative"
	}
}));

export default styles;
