import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		display: "block",
		borderBottom: "1px solid #6b6b6b",
		borderTop: "1px solid #6b6b6b",
		margin: "30px -32px",
		padding: "20px 32px 19px",
		"& li": {
			textAlign: "right",
			position: "relative",
			"&:not(:last-child)": {
				marginBottom: "16px"
			}
		},
		"& a": {
			color: "#FFFFFF",
			opacity: 1,
			fontSize: 24,
			"&:hover": {
				opacity: 0.8
			},
			"&:after": {
				top: "11px",
				left: 0,
				width: "12px",
				height: "12px",
				content: "''",
				position: "absolute",
				background: "#FFFFFF"
			}
		},
		[theme.breakpoints.up("lg")]: {

		}
	}
}));

export default styles;
