import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";

import styles from "./Hamburger.styles";

const Hamburger = ({ menuOpen, setMenuOpen }) => {
	const css = styles();

	const detectVariant = count => {
		let initial = null;
		let hover = null;

		switch (count) {
			case 2:
				initial = "80%";
				hover = "100%";
				break;
			case 3:
				initial = "60%";
				hover = "100%";
				break;
			default:
				initial = "100%";
				hover = "100%";
		}

		return {
			animate: {
				opacity: 0,
				x: 5
			},
			initial: {
				x: 0,
				width: initial
			},
			hover: {
				width: hover
			}
		};
	};

	return (
		<div
			className={clsx(css.hamburger, "mco-view-component-hamburger")}
			onClick={() => setMenuOpen(!menuOpen)}
		>
			<motion.div
				animate={menuOpen ? "animate" : "initial"}
				className={clsx(css.inner, "mco-view-component-hamburger__inner")}
				initial="initial"
				whileHover="hover"
			>
				{[...Array(3)].map((x, i) => (
					<motion.div
						className={clsx(css.bar, "mco-view-component-hamburger__bar")}
						key={i + 1}
						variants={detectVariant(i + 1)}
					/>
				))}
			</motion.div>
		</div>
	);
};

export default Hamburger;
