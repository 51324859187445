import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	MenuList: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		margin: "0 auto",
		[theme.breakpoints.up("lg")]: {
			paddingLeft: 10
		}
	}
}));

export default styles;
