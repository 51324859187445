import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(10),
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(5)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(5)
		},
		[theme.breakpoints.up("sm")]: {
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(7)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(7)
			}
		},
		[theme.breakpoints.up("md")]: {
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(10.5)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(10.5)
			}
		},
		"& .mco-dropdown-01__details > div": {
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(4),
			padding: theme.spacing(4, 0)
		}
	}
}));

export default styles;
