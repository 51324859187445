import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.up("lg")]: {
			paddingRight: 300
		}
	}
}));

export default styles;
