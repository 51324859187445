import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
//import Container from "../Container";
import Level1 from "./Level1";
import MenuHeader from "./MenuHeader";

import styles from "./Mobilemenu.styles";

const MobileMenu = ({ menuGroup, menuOpen, setMenuOpen }) => {
	const css = styles();
	const container = useRef(null);
	const [selectedMenuLevel1ID, setSelectedMenuLevel1ID] = useState();
	const [submenuIsActive, setSubmenuIsActive] = useState();
	const menuLevel1Ref = useRef();
	const menuLevel2Ref = useRef();

	useEffect(() => {
		disableBodyScroll(container.current);

		return () => {
			if (menuOpen) {
				clearAllBodyScrollLocks();
			}
		};
	}, [menuOpen]);

	const handleLevel1Click = level1ID => {
		setSubmenuIsActive(true);
		if (level1ID === selectedMenuLevel1ID) {
			setSelectedMenuLevel1ID(null);
			return;
		}

		setSelectedMenuLevel1ID(level1ID);
	};

	useEffect(() => {
		const selectedMainPoint = menuGroup?.items?.find(item => item.selected);
		setSelectedMenuLevel1ID(selectedMainPoint?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={clsx(css.container, "mco-view-component-megamenu__container")}
			ref={container}
		>
			<MenuHeader menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			<div
				className={clsx(css.mobilemenu, "mco-view-component-mobilemenu")}
				maxWidth="lg"
			>
				<div
					className={clsx(css.column1, "mco-view-component-mobilemenu__column1", {
						[css.submenuActive]: submenuIsActive
					})}
					ref={menuLevel1Ref}
				>
					<Level1
						handleLevel1Click={handleLevel1Click}
						items={menuGroup?.items}
						menuLevel2Ref={menuLevel2Ref}
						selectedMenuLevel1ID={selectedMenuLevel1ID}
					/>
				</div>
			</div>
		</div>
	);
};

export default MobileMenu;
