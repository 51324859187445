export const theme = {
	palette: {
		header: {
			background: "#3a3a3a",
			menu: "#dddddd"
		},
		primary: {
			main: "#000000"
		},
		text: {
			primary: "#1c1c36"
		}
	},
	typography: {
		fontFamily: "'Bank Gothic', sans-serif",
		fontSize: 19
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				":root": {
					"--mco-palette-primary-main": "#000000"
				},
				a: {
					color: "#1c1c36"
				}
			}
		}
	}
};
