import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	megamenu: {
		display: "flex",
		flexDirection: "column",
		inset: 0,
		overflow: "hidden",
		position: "fixed",
		width: "100%",
		zIndex: theme.zIndex.drawer
	},
	container: {
		background: "#3a3a3a",
		display: "flex",
		flexDirection: "column",
		right: "100% !important",
		transition: "right .3s ease",
		height: "100%",
		overflow: "auto",
		padding: "100px 30px 30px",
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 1200,
		"& a:hover": {
			opacity: 0.8,
			textDecoration: "none"
		},
		"& .mco-view-component-mobilemenu__level1 li + li": {
			marginTop: 24
		},
		"& .mco-view-component-mobilemenu__level1 li:not(:last-child)": {
			marginBottom: 16
		}
	}
}));

export default styles;
