import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	location: {
		display: "none",
		[theme.breakpoints.up("lg")]: {
			display: "block",
			color: "#FFFFFF",
			borderTop: "1px solid #6b6b6b",
			marginLeft: -32,
			marginRight: -32,
			padding: "8px 32px",
			textAlign: "center",
			fontSize: 21,
			lineHeight: 1.26,
			"& span": {
				display: "block"
			},
			"& a": {
				display: "block",
				color: "#FFFFFF"
			}
		}
	}
}));

export default styles;
