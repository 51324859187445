import React, { useContext } from "react";
import PageContext from "@PageContext";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import ConsentData from "@micado-digital/react-ui/components/ConsentData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import Locals from "@micado-digital/react-ui/components/Locals";
import Default from "./Default";
import Core from "./Core";

const Views = () => {
	const [pageData] = useContext(PageContext);
	const { authorID, lang } = pageData || {};

	return (
		<WebProfile key={lang}>
			<ConsentData lang={lang}>
				<AccountData authorID={authorID}>
					<Locals url={`/locals/${lang || "de"}.json`}>
						<Default />
						<Core />
					</Locals>
				</AccountData>
			</ConsentData>
		</WebProfile>
	);
};

export default Views;
